#container .content_box h3 {
    border-bottom:0;
}
#container .content_box h3:after {
    width:44px;
    height:1px;
    display:block;
    margin-top:15px;
    background-color:#dcdcdc;
    content: "";
}
.mod_iso_messages .iso_confirm,
.mod_iso_message .iso_confirm {
    margin:0;
    padding:20px;
    background-color:#f6f6f6;
    font-weight:bold;
    color:#4ea666;
}
.mod_iso_addressbook .default_shipping .buttons:after,
.mod_iso_addressbook .default_billing .buttons:after {
    display:block;
    margin-top:-17px;
    margin-right:10px;
    text-align:right;
    font-weight:bold;
}
.mod_iso_addressbook .default_shipping .buttons:after {
    content: "Standard-Versandadresse";
}
.mod_iso_addressbook .default_billing .buttons:after {
    content: "Standard-Rechnungsadresse";
}
.pagination_not {
    height:27px;
}
#welcome1,
#welcome2 {
    margin-top:10px;
}
.home #container h1 {
    margin-bottom:0;
}
#welcome1 .product_list .product,
#welcome2 .product_list .product {
    padding:0;
    border:0;
}
#welcome2 .product_list .product {
    width:320px;
    padding-top:7px;
    padding-bottom:7px;
    background-image:url("../images/division.png");
    background-position:center top;
    background-repeat:no-repeat;
}
#welcome2 .product_list .product_last {
    background-image: url("../images/division.png"), url("../images/division.png");
    background-repeat: no-repeat, no-repeat;
    background-position: center top, center bottom;
}
#welcome1 .product_list a,
#welcome2 .product_list a {
    text-decoration:none;
}
#welcome1 .product_list a:hover,
#welcome2 .product_list a:hover {
    text-decoration:underline;
}
#welcome1 .image_container img,
#welcome2 .image_container img {
    padding:0;
}
#welcome1 .product_list .product .image_container,
#welcome1 .product_list .product .image_empty,
#welcome2 .product_list .product .image_container,
#welcome2 .product_list .product .image_empty {
    background: none;
}
#welcome1 .product_list .product .image_container,
#welcome1 .product_list .product .image_empty {
    width:120px;
    float:left;
    margin-right:10px;
    text-align:center;
}
#welcome2 .product_list .product .image_container,
#welcome2 .product_list .product .image_empty {
    width:80px;
    float:left;
    margin-right:10px;
    text-align:center;
}
#welcome1 .awards {
    margin-top:80px;
}
#welcome1 .column_last,
#welcome2 .column_last {
    float:left;
}
.mod_iso_productlist .product p {
    margin:0;
    font-size:12px;
}
#welcome1 .typeofwine {
    padding-bottom:40px;
    font-size:10px;
    font-style:italic;
}
#welcome2 .typeofwine {
    padding-bottom:10px;
    font-size:10px;
    font-style:italic;
}
#welcome1 .quantity_container {
    float:left;
    margin-top:10px;
    margin-right:5px;
}
#welcome2 .quantity_container {
    float:left;
    margin-top:5px;
    margin-right:5px;
}
#welcome1 .quantity_container .text {
    margin-bottom:2px;
}
#welcome2 .quantity_container .text {
    margin-bottom:0;
}
.pagination {
    float:left;
    clear:both;
}
#default_list .pagination {
    width:200px;
}
.pagination li.first,
.pagination li.last {
    display:none;
}
.pagination p {
    display:none;
}
#container .mod_iso_productreader .image_container,
.mod_iso_productreader .image_empty {
    width:125px;
    height:460px;
    float:left;
    margin-right:0;
}
#container .mod_iso_productreader .product {
    position:relative;
    margin-top:45px;
}
#container .mod_iso_productreader h2 {
    margin-bottom:0;
}
#container .mod_iso_productreader h3 {
    margin-bottom:8px;
}
#container .mod_iso_productreader p {
    font-size:12px;
}
#container .product_list label {
    width:auto;
    margin-right:10px;
}
#container .quantity_container .text,
#main .product_quantity .text,
#container .formbody select {
    width:17px;
    padding-top:7px;
    padding-bottom:7px;
    background-color:#fff;
    border-radius:1px;
    background-image:none;
}
#container #main .formbody select {
    width:auto;
}
::-webkit-input-placeholder {
    color:#000;
}
::-moz-placeholder {
    color:#000;
}
:-ms-input-placeholder {
    color:#000;
}
input:-moz-placeholder {
    color:#000;
}
.baseprice {
    color:#2a793f;
}
#mostpopular,
.ce_iso_producerproducts {
    overflow:visible;
    padding:17px;
    background-color:#f2f2f2;
    box-sizing: border-box;
}
#main #mostpopular h2,
#main .ce_iso_producerproducts h2 {
    margin:0 8px 8px;
    font:12px Verdana,Arial,sans-serif;;
    color:#7a7a7a;
    text-transform:uppercase;
}
#main #mostpopular h3,
#main .ce_iso_producerproducts h3 {
    font-size:16px;
}
#main #mostpopular .price,
#main .ce_iso_producerproducts .price {
    font-size:14px;
}
#main #mostpopular .bottle,
#main .ce_iso_producerproducts .bottle {
    font-size:11px;
}
#mostpopular .product_list,
.ce_iso_producerproducts .product_list {
    position:relative;
}
.ribbon {
    left:-6px;
    top:-7px;
    position:absolute;
    z-index:100;
}
.ce_iso_firstproduct .ribbon,
.mod_iso_productreader .ribbon {
    left:0;
    top:0;
}
#toggle-gift {
    float:right;
    margin-right:20px;
    border:0;
}
#toggle-gift span {
    margin-left:30px;
}
#toggle-gift label {
    width:auto;
    float:none;
    display:inline;
    padding:0;
}
.mod_iso_productfilter .text {
    width:167px;
    height:26px;
    margin-right:-5px;
    padding:0 0 0 8px;
    background-color:rgba(255,255,255,.01);
    background-image:url("../images/search_input_filter.png");
    background-repeat:no-repeat;
    border:0;
    line-height:26px;
    color:#aeaeae;
    -webkit-appearance:none;
}
body.firefox .mod_iso_productfilter .text::-moz-focus-inner {
    padding:0;
}
.mod_iso_productfilter .submit {
    width:29px;
    height:26px;
    vertical-align:top;
    background-color:rgba(255,255,255,.01);
    background-image:url("../images/search_button_filter.png");
    background-repeat:no-repeat;
    border:0;
    font-size:0;
    text-indent:-9999em;
    cursor:pointer;
}
#main .mod_iso_cumulativefilter {
    margin-right:5px;
    background-color:#f7f7f5;
    font-size:12px;
}
#main .mod_iso_cumulativefilter ul {
    margin-bottom:0;
    margin-left:0;
    padding-left:0;
}
.mod_iso_cumulativefilter li {
    list-style-type:none;
}
.mod_iso_cumulativefilter .level_2 li.first {
    margin-top:9px;
}
.mod_iso_cumulativefilter .level_2 li.last {
    margin-bottom:9px;
}
#container .mod_iso_cumulativefilter li.active a:hover {
    background-image:url("../images/filter_delet.jpg");
    background-repeat:no-repeat;
    font-weight:bold;
    text-decoration:line-through;
    color:#626154;
    background-position: 175px 10px;
}
.mod_iso_cumulativefilter .label {
    display:block;
    padding:13px 10px 12px;
    background-image:url("../images/filter_down.png");
    background-repeat:no-repeat;
    border-top:4px solid #fff;
    border-bottom:1px solid #fff;
    color:#b19f55;
    text-transform:uppercase;
    background-position:170px 21px;
    cursor:pointer;
}
.mod_iso_cumulativefilter .level_1 ul {
    display:none;
}
.mod_iso_cumulativefilter .level_1 li:hover ul,
.mod_iso_cumulativefilter .level_1 li.trail ul {
    display:block;
}
.mod_iso_cumulativefilter a {
    display:block;
    padding:3px 10px;
    text-decoration:none;
}
.mod_iso_cumulativefilter.area .level_2 strong {
    display:block;
    padding:12px 10px 3px;
    font-weight:bold;
    color:#b19f55;
    text-transform:uppercase;
}
.mod_iso_cumulativefilter.area .level_2 strong.first {
    padding-top:0;
}
#container .mod_iso_cumulativefilter .level_2 a:hover,
#container .mod_iso_cumulativefilter .level_2 li.active a {
    font-weight:bold;
    color:#626154;
}
.mod_iso_cumulativefilter a .result_count {
    font-size:10px;
    color:#333;
}
#main .clearall {
    display:none;
}
#main .clear_filters {
    display:inline-block;
    margin:13px 10px;
}
#default_list .message {
    margin-top:40px;
}
#default_list .product_list .product {
    height:205px;
    float:none;
    clear:both;
    margin-top:10px;
    padding-top:10px;
    padding-bottom:10px;
    border-width:1px 0px;
    border-style:solid;
    border-color:#d9d9d9;
}
#default_list a {
    text-decoration:none;
    color:#b19f55;
}
#default_list a:hover {
    text-decoration:underline;
}
#default_list .product_list .product_last {
    margin-bottom:10px;
}
#default_list .ribbon {
    left:0;
}
#default_list .product_list .product .image_container,
#default_list .product_list .image_empty {
    width:80px;
    height:205px;
    float:left;
    margin-right:20px;
}
#default_list .product_list .product .image_container a {
    width:80px;
    height:205px;
    display:table-cell;
    margin-right:20px;
    vertical-align:middle;
    text-align:center;
}
#default_list .column_one {
    width:220px;
    float:left;
    margin-top:10px;
}
#default_list .row_one {
    padding-top:2px;
    font-size:12px;
}
#default_list .row_two {
    padding-top:3px;
    padding-bottom:23px;
    font-size:10px;
    font-style:italic;
}
#default_list .teaser {
    min-height:48px;
    margin-bottom:13px;
    font-size:10px;
}
#default_list .column_two {
    width:110px;
    float:right;
    margin-top:18px;
    text-align:right;
}
#default_list .quantity_container {
    margin-top:25px;
    margin-bottom:-5px;
}
#default_list .quantity_container label {
    margin-right:0;
    margin-left:25px;
}
#default_list .submit_container .submit {
    float:right;
    margin-bottom:5px;
    text-align:center;
}
#procava_information .image_container {
    margin-top:69px;
    margin-right:0;
}
#main #procava_information img {
    padding:0;
    text-align:right;
    border:0;
}
#default_list .container_box {
    font-size:9px;
}
#welcome1 .submit_container .submit {
    margin-top:10px;
}
#welcome2 .submit_container .submit {
    margin-top:5px;
}
#welcome1 .container_box,
#welcome2 .container_box {
    clear:both;
}
.search label {
    display:none;
}
#verpackung {
    margin-bottom:20px;
    padding-top:20px;
    padding-bottom:10px;
    background-color:#f6f6f6;
    border-bottom:2px solid #d9dada;
}
#verpackung .gift {
    margin-left:15px;
    padding-left:50px;
    background-image:url("../images/cart_gift.png");
    background-position:left top;
    background-repeat:no-repeat;
}
#packaging {
    margin:20px 0;
}
.gift p {
    padding-top:5px;
    font-size:12px;
}
.gift em {
    font-size:11px;
    font-style:italic;
}
#main .product_list .product img {
    border:0;
}
.product_list .product {
    position:relative;
    float:left;
    padding:7px 7px 17px;
    background-color:#fff;
    border:1px solid #d9dada;
}
#packaging .product_list .product {
    width:197px;
    height:264px;
    margin-right:12px;
}
#mostpopular .product_list .product,
.ce_iso_producerproducts .product_list .product {
    width:148px;
    height:216px;
    margin:8px;
}
#packaging .product_list .product_first {
    margin-left:15px;
}
#mostpopular .product_list .product_last,
.ce_iso_producerproducts .product_list .product_last {
    margin-right:0;
}
.product_list .product .image_container,
.product_list .product .image_empty {
    display:table-cell;
    vertical-align:middle;
    text-align:center;
    background-color:#e6e6e6;
    background:-moz-linear-gradient(top,#e6e6e6,#fafafa);
    background:-webkit-linear-gradient(top,#e6e6e6,#fafafa);
    background:-o-linear-gradient(top,#e6e6e6,#fafafa);
    background:-ms-linear-gradient(top,#e6e6e6,#fafafa);
    background:linear-gradient(to bottom,#e6e6e6,#fafafa);
    -pie-background:linear-gradient(top,#e6e6e6,#fafafa);
}
#packaging .product_list .product .image_container {
    width:197px;
    height:138px;
}
#mostpopular .product_list .product .image_container,
#mostpopular .product_list .image_empty,
.ce_iso_producerproducts .product_list .product .image_container,
.ce_iso_producerproducts .product_list .image_empty {
    width:148px;
    height:148px;
}
#main .product_list .product h3 {
    margin-top:7px;
    margin-bottom:0;
}
.product_list .product .notice {
    margin-bottom:24px;
    font-size:10px;
}
.product_list .product .price {
    font-size:18px;
}
.product_list .product .price.discount {
    color:#f00;
}
.product_list .product .price span {
    color:#333;
}
.product_list .product .tax {
    font-size:10px;
    font-style:italic;
    color:#aaa;
}
.product_list .product .currency {
    font-size:11px;
}
.product_list .product .button_cart {
    margin-top:7px;
}
#mostpopular .product .show_buttons,
.ce_iso_producerproducts .product .show_buttons {
    display:none;
}
#mostpopular .product:hover .show_buttons,
.ce_iso_producerproducts .product:hover .show_buttons {
    width:162px;
    height:178px;
    left:0;
    top:0;
    position:absolute;
    display:block;
    padding-top:62px;
    text-align:center;
    background-color:rgba(255,255,255,.7);
    z-index:10;
}
#mostpopular .submit_container,
.ce_iso_producerproducts .submit_container {
    text-align:center;
}
#mostpopular .submit_container .submit,
.ce_iso_producerproducts .submit_container .submit,
#default_list .submit_container .submit,
.mod_iso_productreader .submit_container .submit,
#welcome1 .submit_container .submit,
#welcome2 .submit_container .submit,
.button_cart {
    width:97px;
    height:30px;
    background-image:url("../images/button_cart.png");
    background-position:left top;
    background-repeat:no-repeat;
    border:0;
    font-size:.1px;
    cursor:pointer;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
#mostpopular .details,
#mostpopular .details a,
.ce_iso_producerproducts .details,
.ce_iso_producerproducts .details a {
    margin:0 auto;
}
#mostpopular .details a,
.ce_iso_producerproducts .details a {
    width:97px;
    height:30px;
    display:block;
    margin-bottom:20px;
    background-image:url("../images/button_more.png");
    background-position:left top;
    background-repeat:no-repeat;
    font-size:.1px;
    cursor:pointer;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
#mostpopular .submit_container .submit:hover,
#mostpopular .details a:hover,
.ce_iso_producerproducts .submit_container .submit:hover,
.ce_iso_producerproducts .details a:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
}
#mostpopular .submit_container .sold_out,
.ce_iso_producerproducts .submit_container .sold_out,
#default_list .submit_container .sold_out,
.mod_iso_productreader .submit_container .sold_out,
#welcome1 .submit_container .sold_out,
#welcome2 .submit_container .sold_out {
    width:97px;
    height:30px;
    background-image:url("../images/button_soldout.png");
    background-position:left top;
    background-repeat:no-repeat;
    border:0;
    border-radius:3px;
    text-indent:-999em;
}
#mostpopular .slider-control,
.ce_iso_producerproducts .slider-control {
    height:auto;
    position:static;
}
#mostpopular .slider-menu,
.ce_iso_producerproducts .slider-menu {
    display:none !important;
}
#mostpopular .slider-prev,
#mostpopular .slider-next,
.ce_iso_producerproducts .slider-prev,
.ce_iso_producerproducts .slider-next {
    width:24px;
    height:60px;
    top:50%;
    position:absolute;
    margin-top:-30px;
    background-image:url("../images/mostpopular_arrows.jpg");
    background-repeat:no-repeat;
}
#mostpopular .slider-prev,
.ce_iso_producerproducts .slider-prev {
    left:-21px;
    background-position:left top;
}
#mostpopular .slider-next,
.ce_iso_producerproducts .slider-next {
    right:-21px;
    background-position:right top;
}
.deliverable span {
    color:#02bf35;
}
#container .mod_iso_productreader img {
    padding-right:0;
    padding-left:0;
    border:0;
}
.awards {
    height:55px;
    position:relative;
    margin-top:20px;
    margin-bottom:20px;
    line-height:1.2;
    font-style:italic;
}
.data_table {
    width:319px;
    float:left;
    margin-bottom:20px;
    padding-bottom:20px;
    border-bottom:1px solid #d9dada;
    font-size:12px;
}
.data_table .column_first {
    width:115px;
    float:left;
    padding-bottom:10px;
}
.data_table .column_last {
    padding-bottom:10px;
}
.mod_iso_productreader .product_price {
    width:130px;
    float:left;
    margin-top:3px;
}
.mod_iso_productreader .price {
    font-size:20px;
}
.mod_iso_productreader .price span {
    font-size:12px;
    color:#333;
}
.product_list .old_price,
.mod_iso_productreader .old_price {
    position:relative;
    display:inline-block;
}
.product_list .old_price:before,
.mod_iso_productreader .old_price:before {
    height:1px;
    left:0;
    right:0;
    top:.8em;
    position:absolute;
    background-color:#f00;
    content: "";
}
.mod_iso_productreader .price.discount {
    color:#f00;
}
.mod_iso_productreader .tax,
.mod_iso_productreader .shipping {
    font-size:10px;
    font-style:italic;
    color:#aaa;
}
.mod_iso_productreader .product_submit {
    width:189px;
    height:80px;
    float:left;
    text-align:right;
}
.mod_iso_productreader label {
    width:auto;
    float:left;
}
#container .mod_iso_productreader .text {
    float:left;
    margin-left:5px;
}
.mod_iso_productreader .submit_container .submit {
    float:right;
}
.mod_iso_productreader .container_box {
    float:right;
    display:block;
}
.mod_iso_productreader .deliverable,
.mod_iso_productreader .share {
    width:157px;
    float:left;
    margin-top:20px;
    padding-top:10px;
    border-top:1px solid #d9dada;
    font-size:10px;
}
.mod_iso_productreader .share a:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
}
#container .mod_iso_productreader .share {
    margin:20px 0 0;
    padding:10px 0 0;
    text-align:right;
}
.mod_iso_productreader .share li {
    display:inline-block;
    list-style-type:none;
}
#container .pagination ul {
    margin:0;
    padding:0;
}
.pagination ul,
.pagination li {
    display:inline-block;
}
.pagination li {
    margin-bottom:10px;
    vertical-align:middle;
}
.pagination li {
    margin-right:5px;
    list-style-type:none;
}
.pagination a,
.pagination span.current {
    display:block;
}
#main .pagination a {
    text-decoration:none;
    color:#b19f55;
}
#main .pagination a:hover {
    text-decoration:underline;
}
.pagination span.current {
    background-color:#f7f7f5;
}
#default_list .sorting {
    float:right;
}
#default_list .sorting label {
    width:auto;
    padding-top:0;
    padding-right:3px;
}
#default_list .sorting .select {
    width:auto;
    float:left;
    margin:0;
    padding:2px 10px 0 0;
    background-color:#fff;
    background-image:url("../images/filter_sorting.png");
    background-repeat:no-repeat;
    border:0;
    color:#b19f55;
    background-position: right 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
}
.firefox #default_list .sorting .select {
    padding-top:1px;
}
#default_list .clear_filters {
    display:none;
}
#coupon-list .product {
    float:none;
    padding-bottom:4px;
    border-right:0px;
    border-bottom:0px;
    border-left:0px;
}
#coupon-list .product.product_last {
    border-bottom:1px solid #d9dada;
}
#coupon-list .product h3 {
    float:left;
}
#coupon-list .product label {
    float:none;
    display:inline-block;
    margin-left:5px;
}
#coupon-list .product .submit_container {
    float:right;
}
#coupon-list .product .quantity_container {
    display:inline-block;
}
#coupon-list .product .options {
    display:inline-block;
    margin-right:20px;
}
#coupon-list .product .options input {
    width:50px;
}
#coupon-list .product .options label.error,
#coupon-list .product .options input.error {
    border-color:#f00;
    color:#f00;
}
#coupon-list .product .options p.error {
    display:none;
}
#coupon-list .product .submit {
    display:inline-block;
}
#coupon-list .product:after {
    content: "";
    display: table;
    clear: both;
}
.order_products {
    margin-top:24px;
}
.mod_iso_cart p.empty {
    margin-left:17px;
}
.mod_iso_cart table {
    width:100%;
}
.mod_iso_cart th {
    padding-bottom:12px;
    text-align:left;
    border-bottom:1px solid #d9dada;
    font-size:12px;
    font-weight:normal;
    color:#b19f55;
    text-transform:uppercase;
}
.mod_iso_cart td {
    padding-top:10px;
    padding-bottom:10px;
    vertical-align:top;
    border-bottom:1px solid #d9dada;
    font-size:11px;
}
.mod_iso_cart .row_last td {
    border-bottom:2px solid #d9dada;
}
.mod_iso_cart .coupon {
    float:right;
}
.mod_iso_cart .coupon p.failed {
    color:#f00;
}
.mod_iso_cart .coupon input.text {
    width: 190px !important;
}
#product_cart .coupon {
    margin:25px 0 0;
}
.steps {
    margin-bottom:40px;
}
#container .formbody span.mandatory {
    display:inline;
    padding-left:5px;
    color:#de0000;
}
#main .steps ul {
    margin-left:3px;
    padding:0;
}
.mini_cart {
    width:142px;
    height:48px;
    right:37px;
    top:44px;
    position:absolute;
    background-image:url("../images/mini_cart.png");
    background-position:left top;
    background-repeat:no-repeat;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
.mini_cart:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
}
.mini_cart .unit {
    width:62px;
    float:left;
    margin-top:10px;
    text-align:center;
    font-size:14px;
    color:#fff;
}
.mini_cart .cart {
    width:86px;
    float:right;
    margin-top:3px;
    margin-left:-27px;
    text-align:right;
}
.mini_cart .title {
    font-size:12px;
    color:#4ea666;
    text-transform:uppercase;
}
.mini_cart .amount {
    font-size:13px;
    color:#505050;
}
.mini_cart .amount .currency {
    font-size:10px;
    text-transform:uppercase;
}
.mini_cart .tax {
    font-size:9px;
    line-height:1.2;
    font-style:italic;
    color:#aaa;
}
#main .mod_iso_cart .product_image {
    width:70px;
    height:109px;
    padding-right:0;
    text-align:center;
}
#main .product_image .image_container {
    margin-right:0;
}
#main .product_image img {
    padding:0;
    border:0;
}
.product_name {
    width:234px;
}
#main .product_name a {
    text-decoration:none;
    color:#333;
}
.product_price {
    width:90px;
}
.product_quantity {
    width:90px;
}
.product_subtotal {
    width:90px;
}
.product_action {
    width:40px;
}
#main .mod_iso_cart .order_total {
    font-size:12px;
}
.mod_iso_cart .submit_container,
#product_cart .row_3 td {
    text-align:center;
}
#main .order_total .box {
    padding-right:15px;
    padding-left:15px;
    background-color:#f6f6f6;
    border-top:5px solid #f6f6f6;
}
#main .order_total h2 {
    margin-bottom:0;
    padding-bottom:16px;
    border-bottom:1px solid #fff;
    font-size:24px;
    font-weight:normal;
    color:#b19f55;
}
.order_total .currency {
    font-size:9px;
}
.order_total .percent {
    font-size:9px;
}
.order_total .subtotal,
.order_total .surcharge,
.order_total .total {
    padding-top:10px;
    padding-bottom:10px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #ffffff;
}
.order_total .total {
    border-bottom:0;
    font-weight:bold;
}
.order_total .subtotal .label,
.order_total .surcharge .label,
.order_total .total .label {
    float:left;
    padding-right:10px;
}
.order_total .subtotal .price,
.order_total .surcharge .price,
.order_total .total .price {
    text-align:right;
}
.order_total .deliverable {
    padding-top:18px;
    padding-left:20px;
    font-size:10px;
}
.button_checkout {
    width:154px;
    height:30px;
    margin-top:20px;
    margin-bottom:15px;
    background-image:url("../images/button_checkout_txt.png");
    background-position:left top;
    background-repeat:no-repeat;
    border:0;
    font-size:.1px;
    cursor:pointer;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
.button_cart {
    margin-top:20px;
    margin-bottom:15px;
}
.button_checkout:hover,
.button_cart:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
}
#container .submit_button a {
    width:140px;
    height:30px;
    display:block;
    background-image:url("../images/button_continue.png");
    background-repeat:no-repeat;
    text-indent: -9999px;
    cursor:pointer;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
#container .submit_button a:hover,
#container .submit_container .submit:hover {
    opacity:0.7;
    filter:alpha(opacity=70);
}
#einkaufen {
    margin-left:20px;
}
.button_update,
.remove {
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
.button_update:hover,
.remove:hover {
    opacity:0.3;
    filter:alpha(opacity=30);
}
.steps li {
    width:120px;
    float:left;
    margin-bottom:10px;
    font-size:14px;
    color:#ccc;
    list-style-type:none;
}
#container .steps a {
    text-decoration:none;
    color:#b19f55;
}
.steps .here {
    display:table;
    margin-top:10px;
    margin-bottom:10px;
}
.steps .number {
    width:30px;
    height:30px;
    display:table-cell;
    vertical-align:middle;
    text-align:center;
    background-color:#eaeaea;
    border:4px solid #fff;
    border-radius:50%;
    color:#ccc;
    box-shadow: 0 0 0 2px #eaeaea;
}
.steps .active {
    color:#b19f55;
}
.steps .active .number {
    background-color:#b19f55;
    color:#fff;
    box-shadow: 0 0 0 2px #b19f55;
}
.steps .passed .number {
    background-color:#b19f55;
    color:#fff;
    box-shadow: 0 0 0 2px #b19f55;
}
.mod_login {
    margin-bottom:12px;
}
.guest,
.billingaddress,
.shippingaddress {
    margin-top:30px;
}
#iso_mod_checkout_address .billingaddress,
#iso_mod_checkout_address .shippingaddress {
    width:45%;
    float:left;
    margin-top:0;
}
#iso_mod_checkout_address .billingaddress {
    margin-right:80px;
}
#iso_mod_checkout_address h2 {
    margin-top:0;
}
#iso_mod_checkout_address .submit_container {
    clear:both;
}
label {
    width:150px;
    float:left;
    display:block;
    padding-top:6px;
    box-sizing: border-box;
}
#container p.error {
    margin-bottom:0;
}
.widget-radio fieldset {
    margin:0 0 24px;
    padding:0;
    border:0;
}
.widget-radio label {
    width:auto;
    float:none;
    display:inline;
}
#container .formbody .text,
#container .formbody .captcha {
    margin-top:0;
}
#container .captcha {
    width:25px;
    margin-right:5px;
}
#container .formbody select {
    width:150px;
    margin-top:0;
    padding-top:7px;
    padding-bottom:7px;
}
#ctrl_billingaddress.radio_container label,
#ctrl_shippingaddress.radio_container label {
    float:left;
    margin-top:-2px;
    margin-bottom:20px;
    margin-left:10px;
    padding-top:0;
}
#ctrl_billingaddress.radio_container input,
#ctrl_shippingaddress.radio_container input {
    float:left;
    clear:left;
    display:block;
    margin-top:0;
}
.orderconditions fieldset {
    margin:0;
    padding:0;
    border:0;
}
.orderconditions label {
    width:auto;
    display:inline;
    padding-top:0;
}
.orderconditions input {
    float:left;
    margin:1px 5px 0 0;
}
.mod_iso_addressbook .even,
.mod_iso_addressbook .odd {
    padding-bottom:20px;
}
.mod_iso_addressbook .even {
    background-color:#f7f7f5;
}
.mod_iso_addressbook .odd {
    background-color:#f2f2f2;
}
.mod_iso_addressbook .buttons {
    margin-top:20px;
    margin-bottom:10px;
    padding-top:3px;
    padding-bottom:3px;
    padding-left:10px;
    background-color:#b19f55;
}
.mod_iso_addressbook .vcard {
    padding-left:10px;
}
#container .mod_iso_addressbook .buttons a {
    text-decoration:none;
    color:#fff;
}
#container .mod_iso_addressbook .buttons a.edit {
    margin-right:3px;
    padding-right:6px;
    border-right:1px solid #fff;
    text-decoration:none;
    color:#fff;
}
#container .mod_iso_addressbook .buttons a:hover {
    text-decoration:underline;
}
.mod_iso_addressbook .widget-checkbox fieldset,
#iso_mod_checkout_address .widget-checkbox fieldset {
    margin:10px 0;
    padding:0;
    border:0;
}
.mod_iso_addressbook .widget-checkbox legend,
#iso_mod_checkout_address .widget-checkbox legend {
    padding-bottom:5px;
    font-weight:bold;
}
.mod_iso_addressbook .widget-checkbox label,
#iso_mod_checkout_address .widget-checkbox label {
    float:none;
    display:inline;
    padding-top:0;
}
.mod_iso_addressbook .widget-checkbox input,
#iso_mod_checkout_address .widget-checkbox input {
    margin-left:0;
}
.mod_iso_orderhistory table {
    width:100%;
    font-size:12px;
}
.mod_iso_orderhistory th {
    padding:3px 10px;
    text-align:left;
    background-color:#b19f55;
    font-weight:normal;
    color:#fff;
}
.mod_iso_orderhistory td {
    padding:3px 10px;
    border-top:1px solid #fff;
}
.mod_iso_orderhistory td.col_last {
    text-align:right;
}
.mod_iso_orderhistory .even td {
    background-color:#f7f7f5;
}
.mod_iso_orderhistory .odd td {
    background-color:#f2f2f2;
}
#product_cart h2 {
    margin-top:6px;
    margin-bottom:0;
    padding-bottom:16px;
    border-bottom:1px solid #fff;
    font-size:22px;
    color:#b19f55;
}
#product_cart a {
    text-decoration:none;
    color:#b19f55;
}
#product_cart a:hover {
    text-decoration:underline;
}
#product_cart table {
    border-collapse: separate;
}
#product_cart .row_0 td {
    padding-top:10px;
    padding-bottom:0;
    border-top:1px solid #e5e5e5;
    border-bottom:0px solid #e5e5e5;
}
#product_cart .row_1 td {
    padding-top:5px;
    padding-bottom:10px;
    border-bottom:1px solid #fff;
}
#product_cart .row_2 td {
    padding-top:10px;
    padding-bottom:10px;
    border-top:1px solid #e5e5e5;
    border-bottom:0px solid #e5e5e5;
    font-weight:bold;
}
#product_cart .row_3 td {
    padding-top:0;
    padding-bottom:10px;
    border-top:0;
    border-bottom:0;
}
#product_cart .col_1 {
    text-align:right;
}
#product_cart .cart {
    padding:10px;
    background-color:#f6f6f6;
}
#product_cart .button {
    width:154px;
    height:30px;
    display:inline-block;
    background-image:url("../images/button_cart_txt.png");
    background-position:left top;
    background-repeat:no-repeat;
    border:0;
    font-size:.1px;
    cursor:pointer;
    -webkit-transition:all 1s;
    -moz-transition:all 1s;
    -o-transition:all 1s;
    -ms-transition:all 1s;
    transition:all 1s;
}
#product_cart .amount {
    font-size:13px;
}
#product_cart .amount .currency {
    font-size:10px;
}
#product_cart .tax {
    font-size:9px;
    font-style:italic;
    font-weight:normal;
    color:#aaa;
}
.review h2 {
    margin-top:0;
}
.review .info_container,
.mod_iso_orderdetails .info_container,
.review .procava_box1,
.review .procava_box2 {
    width:500px;
    margin-top:20px;
    margin-bottom:20px;
    background-color:#b19f55;
}
#container .review h3,
#container .mod_iso_orderdetails h3,
#container .review .procava_box1 h2,
#container .review .procava_box2 h2 {
    float:left;
    margin:0;
    padding:5px 10px;
    font:11px Verdana,​Arial,​sans-serif;
    color:#000;
}
#container .review a.edit {
    float:right;
    padding:5px 10px;
    text-decoration:none;
    color:#fff;
}
#container .review a.edit:hover {
    text-decoration:underline;
}
.review .info,
.mod_iso_orderdetails .info,
.review .procava_box2 .widget-checkbox {
    clear:both;
    padding:10px 10px 20px;
    background-color:#f7f7f5;
    color:#000;
}
.review .procava_box1 .widget-explanation {
    clear:both;
    padding:10px;
    background-color:#f7f7f5;
    color:#000;
}
.review .procava_box1 .widget-textarea {
    clear:both;
    margin-top:-15px;
    padding-right:10px;
    padding-bottom:20px;
    padding-left:10px;
    background-color:#f7f7f5;
    color:#000;
}
#container .review .procava_box1 .textarea {
    width:460px;
    height:160px;
    padding-top:7px;
    padding-bottom:7px;
    background-color:#fff;
    border-radius:1px;
    background-image: none;
}
#main .review .image_container img {
    border:0;
}
.review table,
.mod_iso_orderdetails table {
    width:500px;
}
.review .row_first td,
.mod_iso_orderdetails .row_first td {
    border-top:1px solid #d9dada;
    border-bottom:1px solid #d9dada;
}
.review td,
.mod_iso_orderdetails td {
    padding-top:5px;
    padding-bottom:5px;
    border-bottom:1px solid #d9dada;
}
.review .col_4,
.mod_iso_orderdetails .col_4 {
    text-align:right;
}
.review .col_5,
.mod_iso_orderdetails .col_5 {
    display:none;
}
.review tr.total,
.mod_iso_orderdetails tr.total {
    font-weight:bold;
}
#ctrl_shippingmethod > span,
#ctrl_paymentmethod > span {
    display:block;
}
