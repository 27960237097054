
/* Basic configuration */
.g1,
.g2,
.g3,
.g4,
.g5,
.g6,
.g7,
.g8,
.g9,
.g10,
.g11,
.g12 {
    float:left;
    display:inline;
    margin-right:17px;
    margin-left:17px;
}

/* Widths */
.g1 {
    width:47px;
}
.g2 {
    width:128px;
}
.g3 {
    width:209px;
}
.g4 {
    width:290px;
}
.g5 {
    width:371px;
}
.g6 {
    width:452px;
}
.g7 {
    width:533px;
}
.g8 {
    width:614px;
}
.g9 {
    width:695px;
}
.g10 {
    width:776px;
}
.g11 {
    width:857px;
}
.g12 {
    width:938px;
}

/* Offsets */
.offset1 {
    margin-left:98px;
}
.offset2 {
    margin-left:179px;
}
.offset3 {
    margin-left:260px;
}
.offset4 {
    margin-left:341px;
}
.offset5 {
    margin-left:422px;
}
.offset6 {
    margin-left:503px;
}
.offset7 {
    margin-left:520px;
}
.offset8 {
    margin-left:665px;
}
.offset9 {
    margin-left:746px;
}
.offset10 {
    margin-left:827px;
}
.offset11 {
    margin-left:891px;
}

/* Apply margin to content elements by default */
.mod_article>.block {
    margin-right:17px;
    margin-left:17px;
}

/* Except if the article itself is floated */
.g1 .block,
.g2 .block,
.g3 .block,
.g4 .block,
.g5 .block,
.g6 .block,
.g7 .block,
.g8 .block,
.g9 .block,
.g10 .block,
.g11 .block,
.g12 .block {
    margin-right:0;
    margin-left:0;
}

/* Reset grid on specific elements */
.gr {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
