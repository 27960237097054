body.ie8 #mainmenu .level_1 > li > a,
body.opera #mainmenu .level_1 > li > a {
    padding-right:26px;
    padding-left:26px;
}
body.ie8 #slider .ce_powerslide_previews .ce_powerslide_preview p,
body.opera #slider .ce_powerslide_previews .ce_powerslide_preview p {
    line-height:14px;
}
body.opera #slider .ce_powerslide_previews .ce_powerslide_preview {
    padding:10px 24px 18px;
}
body.ie #search_bar .text,
body.opera #search_bar .text {
    right:27px;
    float:left;
    padding-top:0;
    background-position:left 1px;
}
body.ie #search_bar .submit,
body.opera #search_bar .submit {
    top:1px;
}
body.ie8 #container .formbody .text,
body.ie8 #container .formbody .textarea {
    border:1px solid #cecece !important;
    background:#f9f9fa url("../images/input_bg.png") left top repeat-x !important;
    color:#aeaeae !important;
}
