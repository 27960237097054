html {
    background: url("../images/bg.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#header .inside {
    padding-top:100px;
}
body {
    border-top:37px solid #a8a38f;
    font:12px/18px Verdana,Arial,sans-serif;
    color:#333;
}
strong {
    font-weight:bold;
}
#wrapper {
    width:1013px;
    margin:0 auto;
    background-color:#fff;
}
#container {
    padding:24px 20px 38px;
}
#footer {
    padding-right:40px;
    padding-left:40px;
}
#logo {
    width:306px;
    height:96px;
    left:25px;
    top:-38px;
    position:absolute;
    background-image:url("../images/logo.png");
    background-repeat:no-repeat;
}
#logo a {
    width:100%;
    height:100%;
    display:block;
}
#top_bar_login {
    right:230px;
    top:-28px;
    position:absolute;
    font-size:11px;
    line-height:15px;
    color:#fff;
}
#top_bar_login li {
    float:left;
    padding-right:15px;
    padding-left:15px;
    border-right:1px solid #c7c4b2;
}
#top_bar_login li.login {
    padding-left:16px;
    background-image:url("../images/icon_login.png");
    background-position:left center;
    background-repeat:no-repeat;
}
#top_bar_login li.last {
    padding-right:0;
    padding-left:15px;
    border-right:0;
}
#top_bar_login a {
    text-decoration:none;
    color:#fff;
}
#top_bar_login a:hover {
    color:#c7c4b2;
}
#top_bar {
    left:380px;
    top:21px;
    position:absolute;
    text-align:center;
    font-size:12px;
    line-height:18px;
    color:#b6ad88;
    text-transform:uppercase;
}
#top_bar .support_info {
    display:block;
    padding-top:40px;
    background-image:url("../images/icon_beratung.png");
    background-position:center top;
    background-repeat:no-repeat;
}
#top_bar a {
    text-decoration:none;
    color:#b6ad88;
}
#search_bar {
    width:180px;
    height:28px;
    right:37px;
    top:-32px;
    position:absolute;
}
#search_bar .formbody {
    position:relative;
}
#search_bar .text {
    width:130px;
    height:26px;
    right:29px;
    position:absolute;
    padding:8px 0 8px 2px;
    background-color:rgba(255,255,255,.01);
    background-image:url("../images/search_input.png");
    border:0;
    line-height:26px;
    color:#aeaeae;
    -webkit-appearance:none;
}
body.firefox #search_bar .text::-moz-focus-inner {
    padding:0;
}
body.firefox #search_bar .text {
    right:13px;
    padding:0 8px;
}
#search_bar .submit {
    width:29px;
    height:26px;
    right:0;
    position:absolute;
    background-color:rgba(255,255,255,.01);
    background-image:url("../images/search_button.png");
    background-repeat:no-repeat;
    border:0;
    font-size:0;
    text-indent:-9999em;
    cursor:pointer;
}
#slider {
    height:388px;
    background-image:url("../images/slider_bg.jpg");
    background-position:center bottom;
    background-repeat:no-repeat;
}
#slider .ce_sliderStart {
    width:919px;
    height:320px;
    overflow:hidden;
    margin-top:28px;
    margin-right:0;
    margin-left:47px;
}
#slider .ce_sliderStart .slider-control {
    height:15px;
    top:-25px;
    text-align:center;
}
#slider .ce_sliderStart .slider-control .slider-menu {
    width:auto;
    position:static;
    display:inline-block;
    margin:0;
}
#slider .ce_sliderStart .slider-prev,
#slider .ce_sliderStart .slider-next {
    display:none;
}
#slider .ce_sliderStart .slider-control .slider-menu b {
    width:9px;
    height:9px;
    float:left;
    margin-right:4px;
    margin-left:4px;
    border:1px solid #fff;
    border-radius:6px;
    text-indent:-9999px;
}
#slider .ce_sliderStart .slider-control .slider-menu b.active {
    background-color:#fff;
}
#pageimage {
    height:203px;
    background-image:url("../images/pageimage_bg.jpg");
    background-position:center bottom;
    background-repeat:no-repeat;
}
#pageimage .image_container {
    padding-top:22px;
    padding-left:46px;
}
#header .mod_breadcrumb {
    margin-top:18px;
    padding-right:37px;
    padding-left:37px;
    font-size:10px;
    color:#b5b5b5;
}
#header .mod_breadcrumb ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
#header .mod_breadcrumb ul li {
    float:left;
    margin:0;
    padding:0;
}
#header .mod_breadcrumb ul li:after {
    margin-right:4px;
    margin-left:4px;
    content: ">";
}
#header .mod_breadcrumb ul li.last:after {
    display:none;
}
#header .mod_breadcrumb a {
    color:#b5b5b5;
}
#header .mod_breadcrumb .active {
    color:#505050;
}
#mainmenu {
    padding-right:37px;
    padding-left:37px;
}
body.home #mainmenu {
    padding-top:0;
}
#mainmenu .level_1 {
    height:70px;
    margin:0;
    padding:0;
    background-image:url("../images/menu_bg.png");
    background-position:center bottom;
    background-repeat:no-repeat;
    list-style-type:none;
}
#mainmenu .level_1 > li {
    width:170px;
    float:left;
    display:block;
    margin:0;
    padding-right:2px;
    background-image:url("../images/menu_divider.png");
    background-position:right top;
    background-repeat:no-repeat;
}
#mainmenu .level_1 > li > a {
    display:block;
    padding:20px 14px;
    font-size:16px;
    text-decoration:none;
    color:#b19f55;
    text-transform:uppercase;
}
#mainmenu .level_1 > li.last {
    background:none;
}
#mainmenu .level_1 > li:hover > a,
#mainmenu .level_1 > li > .active,
#mainmenu .level_1 > li > .trail {
    background: linear-gradient(#fff, #f1f1f1);
}
body.home #mainmenu .level_1 > li.first:not(:hover) > a,
#mainmenu .level_1 > li.first:not(:hover) > .active,
#mainmenu .level_1 > li.first:not(:hover) > .trail{
    padding-top: 7px;
    padding-bottom: 8px;
    margin-top: 13px;
    margin-bottom: 12px;
    color: #fff;
    background: linear-gradient(#55af6d, #29793f);
}
body.home #mainmenu .level_1 > li.first:not(:hover) > a:after,
#mainmenu .level_1 > li.first:not(:hover) > a.active:after,
#mainmenu .level_1 > li.first:not(:hover) > a.trail:after {
    color: #fff;
}
/*#mainmenu .level_1 > li.home {*/
/*    width:auto;*/
/*    background-image:url("../images/menu_home.png");*/
/*    background-position:left center;*/
/*    background-repeat:no-repeat;*/
/*}*/
#mainmenu .level_1 > li.home > a {
    display:block;
    padding:24px 45px 24px 0;
    text-indent:-999em;
}
#mainmenu .level_1 > li > a:after {
    display:block;
    margin-top:2px;
    padding:0;
    font-size:10px;
    line-height:10px;
    color:#505050;
    text-transform:none;
    content: attr(data-subheadline);
}
#mainmenu .level_2,
#mainmenu .level_3 {
    display:none;
}
#mainmenu .level_1 > li:hover .level_2 {
    display:block;
}
#mainmenu .level_2 {
    width:210px;
    position:absolute;
    background-color:#f6f6f6;
    border-right:1px solid #f6f6f6;
    border-left:1px solid #f6f6f6;
    border-top: 1px solid #fff;
    z-index:99;
}
#mainmenu .level_2 > li {
    border-bottom:1px solid #e0e0e0;
}
#mainmenu .level_2 > li.last {
    border-bottom:0;
}
#mainmenu .level_2 > li > a {
    height:36px;
    display:block;
    padding-right:13px;
    padding-left:13px;
    font-size:11px;
    line-height:36px;
    text-decoration:none;
    color:#666;
}
#mainmenu .level_2 > li > a:hover {
    background-color:#bbb39f;
    text-decoration:none;
    color:#fff;
}
#mainmenu .level_2 > li:hover .level_3 {
    display:block;
}
#mainmenu .level_3 {
    min-width:210px;
    position:absolute;
    margin-top:-36px;
    margin-left:210px;
    background-color:#f6f6f6;
    border-right:1px solid #e0e0e0;
    border-left:1px solid #e0e0e0;
    z-index:99;
}
#mainmenu .level_3 > li {
    border-bottom:1px solid #e0e0e0;
}
#mainmenu .level_3 > li.last {
    border-bottom:0;
}
#mainmenu .level_3 > li > a {
    height:36px;
    display:block;
    padding-right:13px;
    padding-left:13px;
    font-size:11px;
    line-height:36px;
    text-decoration:none;
    color:#666;
}
#mainmenu .level_3 > li > a:hover {
    background-color:#bbb39f;
    text-decoration:none;
    color:#fff;
}
#container h1,
#container h2,
#container h3,
#container h4,
#container h5 {
    margin-bottom:16px;
    font:18px "Garamond","Times New Roman",serif;
    color:#b19f55;
}
#container h2,
#container h3,
#container h4,
#container h5 {
    margin-top:16px;
}
#container h1 {
    font-size:28px;
}
#container h2 {
    font-size:24px;
}
#container p {
    margin-bottom:12px;
}
#container a {
    color:#b19f55;
}
#container .g4 {
    width:290px;
}
#container .mod_article {
    margin-bottom:48px;
}
#container .box_headline {
    margin-bottom:22px;
    padding-bottom:14px;
    font-size:10px;
}
#container .box_headline h2 {
    margin-top:0;
    margin-bottom:0;
}
#container .box_headline p {
    color:#505050;
}
#container #welcome-box .box_headline {
    border-bottom:1px solid #dcdcdc;
}
#container #welcome-box .box_headline p {
    color:#666;
}
#container .content_box h3 {
    margin-bottom:14px;
    padding-bottom:14px;
    border-bottom:1px solid #dcdcdc;
}
#container .image_container {
    margin-right:24px;
}
#main ul {
    margin-right:0;
    margin-left:1em;
    padding-right:0;
    padding-left:1em;
    list-style-type:disc;
    margin-bottom:12px;
}
#main .image_container img {
    padding:6px;
    border:1px solid #d8d8d8;
}
#main .noborder .image_container img {
    padding:0;
    border:0;
}
#container .content_box .image_container {
    margin:0 0 14px;
    padding:0;
    border:0;
}
#container .teaser_box {
    height:178px;
    padding-top:100px;
    text-align:center;
    background-color:#e5e5e5;
}
#container .teaser_box .ce_text {
    margin-right:54px;
    margin-left:54px;
}
#container .teaser_box h2 {
    margin-bottom:0;
}
#container .mod_search > div {
    margin-top:18px;
    padding-bottom:18px;
    border-bottom:1px dashed #A7A28E;
}
#container .mod_search > div.first {
    margin-top:32px;
}
#container .mod_search .relevance {
    color:#D3CDB4;
}
#container .mod_search .url {
    font-weight:bold;
}
#container .mod_search .pagination {
    padding-bottom:0;
    border-bottom:0;
}
#container .mod_search .pagination p {
    float:left;
}
#container .mod_search .pagination ul {
    float:right;
}
#container .mod_search .pagination ul li {
    float:left;
    display:inline;
    margin-right:4px;
    margin-left:4px;
}
#container .mod_search .pagination ul li:last-child {
    margin-right:0;
}
#container .ce_sliderStart {
    width:578px;
    position:relative;
    padding:6px;
    border:1px solid #d8d8d8;
}
#container .ce_sliderStart .slider-control {
    width:578px;
    height:50px;
    left:6px;
    position:absolute;
    margin-top:-25px;
    top: 50%;
}
#container .ce_sliderStart .slider-control .slider-menu {
    display:none;
}
#container .ce_sliderStart .slider-prev,
#container .ce_sliderStart .slider-next {
    width:30px;
    height:50px;
    top:0;
    position:absolute;
    display:block;
    background:#4c4c4c url("../images/slider_left.png") center center no-repeat;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
    text-indent:-9999px;
    z-index:1;
    cursor:pointer;
}
#container .ce_sliderStart .slider-next {
    right:0;
    background-image:url("../images/slider_right.png");
    border-radius:4px 0 0 4px;
}
#container .ce_sliderStart .slider-prev:hover,
#container .ce_sliderStart .slider-next:hover {
    background-color:#D3CDB4;
}
#container .ce_sliderStart .image_container {
    margin-right:0;
}
#container .ce_sliderStart .image_container img {
    display:block;
    padding:0;
    border:0;
}
#footer-links {
    padding:39px 40px 40px;
    background-image:url("../images/footer_bg.png");
    background-repeat:repeat;
}
#container .mod_subscribe {
    height:42px;
    position:relative;
    margin-top:38px;
    background-image:url("../images/newsletter_bg.png");
    background-repeat:no-repeat;
}
#container .mod_subscribe .newsletter_description {
    padding-left:184px;
    line-height:42px;
    color:#fff;
}
#container .mod_subscribe .formbody .text {
    width:300px;
    height:20px;
    left:552px;
    top:10px;
    position:absolute;
    margin:0;
    padding:0;
    border:0;
    color:#666;
    background:transparent none;
}
#container .mod_subscribe .submit {
    width:45px;
    height:42px;
    right:0;
    top:0;
    position:absolute;
    background-image:url("../images/newsletter_submit.png");
    background-repeat:no-repeat;
    border:0;
    font-size:0;
    text-indent:-9999em;
    cursor:pointer;
}
#container .ce_text .image_container {
    position:relative;
}
#container .ce_text .image_container a:hover:before {
    bottom:0;
    left:0;
    right:0;
    top:0;
    position:absolute;
    margin:7px 8px 11px 7px;
    background-color:rgba(0,0,0,.43);
    content:'';
    z-index:10;
}
#container .ce_text .image_container a:hover:after {
    width:155px;
    height:50px;
    left:50%;
    top:50%;
    position:absolute;
    margin-top:-25px;
    margin-left:-78px;
    text-align:center;
    background-color:#1d1d1b;
    border-radius:5px;
    line-height:50px;
    color:#fff;
    text-transform:uppercase;
    content:"weiterlesen";
    z-index:11;
}
#container .formbody {
    color:#333;
}
#container .formbody p.error {
    color:#de0000;
}
#container .formbody span.mandatory {
    display:none;
}
#container .formbody .col_0 {
    padding-right:16px;
}
#container .formbody .text,
#container .formbody .textarea {
    width:214px;
}
#container .formbody .text,
#container .formbody .textarea,
#container .formbody .captcha,
#container .formbody select {
    margin:8px 0;
    padding:8px;
    background:#f9f9fa url("../images/input_bg.png") left top repeat-x;
    border:1px solid #cecece;
    border-radius:5px;
    color:#333;
}
#container .ce_form .widget-submit,
#container .mod_login .widget-submit,
#container .mod_registration .widget-submit,
#container .mod_personalData .widget-submit,
#container .mod_iso_addressbook .widget-submit {
    margin-left:150px;
}
#container .mod_iso_addressbook .widget-submit {
    margin-top:20px;
}
#container .widget-submit .submit {
    position:relative;
    padding:6px 23px 7px;
    background-color:#a7a28e;
    border:0;
    border-radius:3px;
    color:#fff;
    cursor:pointer;
    transition:all 1s;
}
#footer-links .g4 {
    width:172px;
    margin-right:30px;
    margin-left:30px;
}
#footer-links .g4 h3 {
    margin-bottom:17px;
    padding-bottom:11px;
    border-bottom:1px solid #dadbdb;
    font-size:15px;
}
#footer-links .g4 a {
    text-decoration:none;
    color:#666;
}
#footer-links .g4 a:hover {
    text-decoration:underline;
}
#footer_copyrights {
    height:54px;
    position:relative;
    text-align:center;
    font-size:12px;
    line-height:54px;
    color:#a3a3a3;
}
#social_links {
    height:21px;
    right:214px;
    top:10px;
    position:absolute;
}
#social_links a {
    width:21px;
    height:21px;
    display:inline-block;
    background-repeat:no-repeat;
}
#social_links .social_facebook {
    background-image:url("../images/social_facebook.png");
}
#social_links .social_twitter {
    background-image:url("../images/social_twitter.png");
}
#social_links .social_google {
    background-image:url("../images/social_google.png");
}

.ce_youtube {
    margin-top: 40px;
    margin-bottom: 40px;
}

.top-20 {
    margin-top: 20px;
}
.top-25 {
    margin-top: 25px;
}
.top-30 {
    margin-top: 30px;
}

.mod_article.produzenten {
    display: flex;
    flex-wrap: wrap;
    padding-left: 81px;
}
.mod_article.produzenten > * {
    width:290px;
}
